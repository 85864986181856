<template>
  <div class="code-tab-container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(codeTemplate, index) in question.codeQuestionTemplates"
        :key="index"
        :label="codeTemplate.codeType"
        :name="codeTemplate.codeType"
      >
        <template v-if="activeName === codeTemplate.codeType">
          <pre class="line-numbers" :class="codeTypes[codeTemplate.codeType]">
            <code>{{ codeTemplate.template }}</code>
          </pre>
        </template>
      </el-tab-pane>
    </el-tabs>
    <div class="tools" v-if="hasTool">
      <div class="tool-button" style="margin-right: 16px" @click="toShowTestCaseModal">
        <YTIcon :href="'#icon-ceshiyongli'"></YTIcon>
        测试用例
      </div>
      <div class="tool-button">
        <YTIcon :href="'#icon-zuoti'"></YTIcon>
        调试
      </div>
    </div>
    <TestCaseMaintainModal ref="testcase-modal" v-model="showTestCaseModal" @refresh="refreshQuestionList" />
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import TestCaseMaintainModal from '@components/common/old/question_handle/TestCaseMaintainModal'
import Prism from 'prismjs'
export default {
  name: 'Code',
  components: { YTIcon, TestCaseMaintainModal },
  props: ['question', 'readOnly', 'hasTool'],
  data() {
    return {
      activeName: 'JavaScript',
      codeTypes: {
        java: 'language-java',
        JavaScript: 'language-javascript',
        c: 'language-c',
        'c++': 'language-cpp',
        python: 'language-python',
        bash: 'language-shell'
      },
      showTestCaseModal: false //代码题测试用例
    }
  },
  mounted() {
    this.$nextTick(() => {
      Prism.highlightAll()
      // Prism.highlightElement(document.getElementsByClassName(this.codeTypes[this.codeTemplate.codeType])[0])
    })
  },
  methods: {
    handleClick() {
      this.$nextTick(() => {
        Prism.highlightElement(document.getElementsByClassName(this.codeTypes[this.codeTemplate.codeType])[0])
      })
    },
    toShowTestCaseModal() {
      this.$refs['testcase-modal'].setQuestionId(this.question.id)
      this.showTestCaseModal = true
    },
    getValue(value) {
      this.$emit('input', value, this.activeName)
    },
    refreshQuestionList() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.el-tabs {
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
::v-deep .el-tabs__header {
  border-bottom: none;
  margin-bottom: 10px;
}
::v-deep .el-tabs__nav {
  border-left: none !important;
  border-top: none !important;
  border-radius: 0 !important;
}
::v-deep .el-tabs__item {
  font-size: @small;
  border-bottom: 1px solid #e9e9e9 !important;
}
::v-deep .is-active {
  border-bottom: none !important;
}
.code-tab-container {
  position: relative;
}
pre {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
code {
  display: block !important;
  margin: 0 !important;
  white-space: pre !important;
}
.tools {
  .flexStyle(flex, center, center);
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 20;
  .tool-button {
    cursor: pointer;
    &:hover {
      color: #448bff;
    }
  }
}
</style>

<template>
  <div>
    <!--题干-->
    <div class="stem">
      <template v-if="orderOption.show">{{ question.index + 1 }}.&nbsp;</template>
      <div v-html="question.stem"></div>
    </div>
    <template v-if="question.questionType !== ytConstant.questionType.CODE || codeTemplateOption.show">
      <component :is="enums[question.questionType].component" :question="question" style="margin-top: 16px"></component>
    </template>
    <div class="answer" v-if="myAnswerOption.show" :style="{ color: question.isCorrect ? '#06C281' : '#ea4e18' }">
      <div style="flex-shrink: 0;letter-spacing: 1px">考生答<span style="letter-spacing: 5px">案:</span></div>
      <template v-if="question.questionType === 5">
        <Code :code="myAnswer" />
      </template>
      <template v-else>
        <div v-html="myAnswer"></div>
      </template>
    </div>
    <div class="answer">
      <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
      <div v-html="correctAnswer"></div>
    </div>
    <div style="display: flex; margin-top: 16px;">
      <div style="flex-shrink: 0">
        <span style="letter-spacing: 12px">解</span>
        <span style="letter-spacing: 5px">析:</span>
      </div>
      <p v-html="question.analysis"></p>
    </div>
    <div class="prop-wrapper">
      <div v-if="tagOption.show" class="prop-block" :class="{ 'has-border': tagOption.border }">
        <span style="letter-spacing: 12px">标</span>
        <span style="letter-spacing: 5px">签:</span>
        <template v-for="(tag, tIndex) in question.tags">
          <div :key="tIndex">
            <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
              <div class="prop-item" :key="tIndex" v-if="tIndex < options.showSize">
                {{ tag.name }}
              </div>
            </el-tooltip>
          </div>
        </template>
        <el-popover
          placement="top"
          width="250"
          trigger="hover"
          title="题目标签"
          v-if="question.hasOwnProperty('tags') && question.tags && options.showSize < question.tags.length"
        >
          <div class="prop-list">
            <template v-for="(tagPop, tPopIndex) in question.tags">
              <div :key="tPopIndex">
                <el-tooltip effect="dark" :disabled="tagPop.name.length < 4" :content="tagPop.name" placement="top" :open-delay="1000">
                  <div class="prop-item" :key="tPopIndex" v-if="tPopIndex >= options.showSize" style="margin-bottom: 5px">
                    {{ tagPop.name }}
                  </div>
                </el-tooltip>
              </div>
            </template>
          </div>
          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
        </el-popover>
      </div>
      <div v-if="abilityOption.show" class="prop-block" :class="{ 'has-border': abilityOption.border }">
        <span style="letter-spacing: 12px">技</span>
        <span style="letter-spacing: 5px">能:</span>
        <template v-for="(skill, tIndex) in question.abilityList">
          <div :key="tIndex">
            <el-tooltip effect="dark" :disabled="skill.abilityName.length < 4" :content="skill.abilityName" placement="top" :open-delay="1000">
              <div class="prop-item" :key="tIndex" v-if="tIndex < options.showSize">
                {{ skill.abilityName }}
              </div>
            </el-tooltip>
          </div>
        </template>
        <el-popover
          placement="top"
          width="250"
          trigger="hover"
          title="技能标签"
          v-if="question.hasOwnProperty('abilityList') && question.abilityList && options.showSize < question.abilityList.length"
        >
          <div class="prop-list">
            <template v-for="(skillPop, sIndex) in question.abilityList">
              <div class="prop-item" :key="sIndex" v-if="sIndex >= options.showSize" style="margin-bottom: 5px">
                {{ skillPop.abilityName }}
              </div>
            </template>
          </div>
          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
        </el-popover>
      </div>
      <div v-if="knowledgeOption.show" class="prop-block" :class="{ 'has-border': knowledgeOption.border }">
        <span style="margin-right: 5px">知识点:</span>
        <template v-for="(knowledge, knowledgeIndex) in question.knowledgePoints">
          <div :key="knowledgeIndex">
            <el-tooltip
              effect="dark"
              :disabled="knowledge.hasOwnProperty('knowledgeName') && knowledge.knowledgeName.length < 4"
              :content="knowledge.hasOwnProperty('knowledgeName') ? knowledge.knowledgeName : '-'"
              placement="top"
              :open-delay="1000"
            >
              <div class="prop-item" :key="knowledgeIndex" v-if="knowledgeIndex < options.showSize">
                {{ knowledge.hasOwnProperty('knowledgeName') ? knowledge.knowledgeName : '-' }}
              </div>
            </el-tooltip>
          </div>
        </template>
        <el-popover
          placement="top"
          width="250"
          trigger="hover"
          title="题目知识点"
          v-if="question.hasOwnProperty('knowledgePoints') && options.showSize < question.knowledgePoints.length"
        >
          <div class="prop-list">
            <template v-for="(knowledgePop, knowledgePopIndex) in question.knowledgePoints">
              <div :key="knowledgePopIndex">
                <el-tooltip
                  effect="dark"
                  :disabled="knowledgePop.hasOwnProperty('knowledgeName') && knowledgePop.knowledgeName.length < 4"
                  :content="knowledgePop.hasOwnProperty('knowledgeName') ? knowledgePop.knowledgeName : '-'"
                  placement="top"
                  :open-delay="1000"
                >
                  <div class="prop-item" style="margin-bottom: 5px" :key="knowledgePopIndex" v-if="knowledgePopIndex >= options.showSize">
                    {{ knowledgePop.hasOwnProperty('knowledgeName') ? knowledgePop.knowledgeName : '-' }}
                  </div>
                </el-tooltip>
              </div>
            </template>
          </div>
          <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px"></YTIcon>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
// 题目卡片组件
import TrueOrFalse from '@components/common/version2/TrueOrFalse'
import Choice from '@components/common/version2/Choice'
import Code from '@components/common/version2/Code'
import CodeTemplate from '@components/common/version2/CodeTemplate'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'QuestionCard',
  components: {
    TrueOrFalse,
    Choice,
    Code,
    CodeTemplate,
    YTIcon
  },
  props: {
    question: {
      type: Object,
      default: function() {
        return {
          index: 0
        }
      }
    },
    options: {
      type: Object
    }
  },
  data() {
    return {
      enums: {
        '0': {
          component: TrueOrFalse,
          myAnswer: 'handleTrueOrFalseMyAnswer',
          correctAnswer: 'handleTrueOrFalseAnswer'
        },
        '1': {
          component: Choice,
          myAnswer: 'handleSingleChoiceMyAnswer',
          correctAnswer: 'handleSingleChoiceAnswer'
        },
        '2': {
          component: Choice,
          myAnswer: 'handleMultipleChoiceMyAnswer',
          correctAnswer: 'handleMultipleChoiceAnswer'
        },
        '3': {
          component: '',
          myAnswer: 'handleFillInBlankMyAnswer',
          correctAnswer: 'handleFillInBlankAnswer'
        },
        '4': {
          component: '',
          myAnswer: 'handleSubjectiveMyAnswer',
          correctAnswer: 'handleSubjectiveAnswer'
        },
        '5': {
          component: CodeTemplate,
          myAnswer: 'handleCodeMyAnswer',
          correctAnswer: 'handleCodeAnswer'
        }
      }
    }
  },
  computed: {
    myAnswer() {
      return this[this.enums[this.question.questionType].myAnswer]()
    },
    correctAnswer() {
      return this[this.enums[this.question.questionType].correctAnswer]()
    },
    orderOption() {
      if (this.options.order) {
        return this.options.order
      }
      return {
        show: true
      }
    },
    codeTemplateOption() {
      if (this.options.codeTemplate) {
        return this.options.codeTemplate
      }
      return {
        show: true
      }
    },
    myAnswerOption() {
      if (this.options.myAnswer) {
        return this.options.myAnswer
      }
      return {
        show: false
      }
    },
    tagOption() {
      if (this.options.tag) {
        return this.options.tag
      }
      return {
        show: true,
        border: false
      }
    },
    abilityOption() {
      if (this.options.ability) {
        return this.options.ability
      }
      return {
        show: true,
        border: true
      }
    },
    knowledgeOption() {
      if (this.options.knowledge) {
        return this.options.knowledge
      }
      return {
        show: true,
        border: true
      }
    }
  },
  methods: {
    handleTrueOrFalseMyAnswer() {
      // 判断题正确答案
      if (this.question.myAnswer) {
        return this.question.myAnswer
      }
      return '暂未作答'
    },
    handleSingleChoiceMyAnswer() {
      // 单选题正确答案
      if (this.question.myAnswer) {
        if (!this.question.options) {
          return this.question.myAnswer
        }
        let answer = this.question.options.findIndex(o => o.content === this.question.myAnswer)
        if (answer !== -1) {
          return String.fromCharCode(answer + 65)
        } else {
          return this.question.myAnswer
        }
      }
      return '暂未作答'
    },
    handleMultipleChoiceMyAnswer() {
      // 多选题正确答案
      if (this.question.myAnswer) {
        if (!this.question.options) {
          return JSON.parse(this.question.myAnswer).join('、')
        }
        let arr = JSON.parse(this.question.myAnswer)
        return arr
          .map(item => {
            let optionIndex = this.question.options.findIndex(o => o.content === item)
            return optionIndex !== -1 ? String.fromCharCode(optionIndex + 65) : item
          })
          .sort()
          .join('、')
      }
      return '暂未作答'
    },
    handleFillInBlankMyAnswer() {
      // 填空题正确答案
      if (this.question.myAnswer) {
        let answer = JSON.parse(this.question.myAnswer)
        return Object.keys(answer)
          .map(vos => {
            return answer[vos][0]
          })
          .join('、')
      }
      return '暂未作答'
    },
    handleSubjectiveMyAnswer() {
      if (this.question.myAnswer) {
        return this.question.myAnswer
      }
      return '暂未作答'
    },
    handleCodeMyAnswer() {
      if (this.question.myAnswer) {
        return JSON.parse(this.question.myAnswer)
      }
      return '暂未作答'
    },
    handleTrueOrFalseAnswer() {
      // 判断题正确答案
      if (this.question.answer && this.question.answer === true) {
        return '正确'
      } else if (this.question.answer && this.question.answer === false) {
        return '错误'
      }
      return '暂无正确答案'
    },
    handleSingleChoiceAnswer() {
      // 单选题正确答案
      if (this.question.options) {
        let find = this.question.options.findIndex(o => o.correct)
        if (find !== -1) {
          return String.fromCharCode(find + 65)
        }
      }
      return '暂无正确答案'
    },
    handleMultipleChoiceAnswer() {
      // 多选题正确答案
      if (this.question.options) {
        let arr = this.question.options
          .filter(item => item.correct)
          .map((t, index) => {
            return String.fromCharCode(index + 65)
          })
        return Array.from(new Set(arr.sort())).join('、')
      }
      return '暂无正确答案'
    },
    handleFillInBlankAnswer() {
      // 填空题正确答案
      if (this.question.blanks) {
        let arr = this.question.blanks.map(m => {
          return m.blankAnswer[0]
        })
        return arr.join('、')
      }
      return '暂无正确答案'
    },
    handleSubjectiveAnswer() {
      return '简答题不提供正确答案'
    },
    handleCodeAnswer() {
      return '代码题不提供正确答案'
    }
  }
}
</script>

<style lang="less" scoped>
.stem {
  display: flex;
  ::v-deep xmp {
    width: 100%;
    white-space: pre-wrap;
  }
}
.answer {
  display: flex;
  color: #06c281;
  margin-top: 16px;
}
.prop-wrapper {
  .flexStyle(flex, flex-start);
  margin-top: 16px;
  .prop-block {
    .flexStyle(flex, flex-start);
    width: calc(100% / 3);
    flex-grow: 1;
    &.has-border {
      margin-left: 8px;
      border-left: 1px solid #d8d8d8;
      padding-left: 16px;
    }
  }
}
.prop-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  max-height: calc(100vh / 2);
  overflow-y: auto;
}
.prop-item {
  max-width: 200px;
  height: 22px;
  line-height: 22px;
  border-radius: 2px;
  color: #2878ff;
  margin-right: 8px;
  padding: 0 20px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>

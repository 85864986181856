<template>
  <pre class="line-numbers">
    <code class="language-java">{{ code.answer }}</code>
  </pre>
</template>

<script>
import Prism from 'prismjs'
export default {
  name: 'Code',
  props: ['code'],
  data() {
    return {
      codeTypes: {
        java: 'java',
        JavaScript: 'javascript',
        c: 'c',
        'c++': 'cpp',
        python: 'python',
        bash: 'shell'
      },
      codeHtml: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      Prism.highlightElement(document.getElementsByClassName('language-java')[0])
    })
  }
}
</script>

<style lang="less" scoped>
pre {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
code {
  display: block !important;
  margin: 0 !important;
  white-space: pre !important;
}
</style>
